$(document).ready(function() {
  "use strict";

  // -----------
  // Mobile menu
  // -----------

  var handleMatchMedia = function(mediaQuery) {
      // ≤ 1023px
      if (mediaQuery.matches) {
        // crear el botó del menú
        // $('.main-nav').prepend('<button class="hamburger hamburger--3dx menu-button" aria-expanded="false" aria-label="Menú" id="nav"><span class="hamburger-box"><span class="hamburger-inner"></span></span><span class="menu-button__label vh">Menú</span></button>')
        // ocultar el menú
        $(".menu-wrapper")
          .attr("hidden", "")
          .addClass("js-hidden");
        // clicar el botó
        $(".main-nav button").on("click", function() {
          // afegir classe al botó
          $(this).toggleClass("is-active");
          // afegir classe al body
          $("body").toggleClass("js-menu-open");
          // canviar atributs del botó i del menú
          if ($(this).attr("aria-expanded") == "true") {
            $(this).attr("aria-expanded", "false");
            $(".menu-wrapper")
              .attr("hidden", "")
              .addClass("js-hidden");
          } else {
            $(this).attr("aria-expanded", "true");
            $(".menu-wrapper")
              .removeAttr("hidden")
              .removeClass("js-hidden");
          }
        });
      } else {
        // ≥ 1024px
        // treure el botó
        // $('.main-nav button').remove();
        // mostrar el menú
        $(".menu-wrapper")
          .removeAttr("hidden")
          .removeClass("js-hidden");
      }
    },
    mq = window.matchMedia("only screen and (max-width: 720px)");
  handleMatchMedia(mq);
  mq.addListener(handleMatchMedia);

  // --------
  // Submenús
  // --------

  // http://heydonworks.com/practical_aria_examples/#submenus
  $(".main-nav ul div")
    .prev("a")
    .attr("aria-haspopup", "true")
    .append('<span aria-hidden="true"> &#x25be;</span>');

  var showSubmenu = function(dropdown) {
    dropdown.attr("aria-hidden", "false");
  };

  var hideSubmenu = function(dropdown) {
    dropdown.attr("aria-hidden", "true");
  };

  $(".with-dropdowns > li > a").on("focus", function(e) {
    hideSubmenu($('[aria-label="submenu"]'));
  });

  $("[aria-haspopup]").on("click", function(e) {
    var submenu = $(this).next();
    showSubmenu(submenu);
    //$(submenu).find('li:first-child a').focus();
    return false;
  });

  $("[aria-haspopup]").hover(function() {
    showSubmenu($(this).next());
    $(this).off("click");
  });

  $("[aria-haspopup]")
    .parents("li")
    .mouseleave(function() {
      hideSubmenu($(this).find('[aria-label="submenu"]'));
    });

  // ----------
  // feature.js
  // ----------

  if (feature.svg) {
    $("html").addClass("svg");
  }

  // --------------------------------------------------------------------------------------
  // skip link (https://www.bignerdranch.com/blog/web-accessibility-skip-navigation-links/)
  // --------------------------------------------------------------------------------------

  // bind a click event to the 'skip' link
  $(".skip-link").click(function(event) {
    // strip the leading hash and declare
    // the content we're skipping to
    var skipTo = "#" + this.href.split("#")[1];

    // Setting 'tabindex' to -1 takes an element out of normal
    // tab flow but allows it to be focused via javascript
    $(skipTo)
      .attr("tabindex", -1)
      .on("blur focusout", function() {
        // when focus leaves this element,
        // remove the tabindex attribute
        $(this).removeAttr("tabindex");
      })
      .focus(); // focus on the content container
  });

  // -----------------
  // Canvi svg per png
  // -----------------

  if (!feature.svg) {
    var imgs = document.getElementsByTagName("img");
    var endsWithDotSvg = /.*\.svg$/;
    var i = 0;
    var l = imgs.length;
    for (; i !== l; ++i) {
      if (imgs[i].src.match(endsWithDotSvg)) {
        imgs[i].src = imgs[i].src.slice(0, -3) + "png";
      }
    }
  }

  // -------
  // Acordió
  // -------
      $('.acord').on('click','.despl',function(){
        if ($(this).hasClass('open')) {
          $('.acord .despl').removeClass('open');
        } else {
          $('.acord .despl').removeClass('open');
          $(this).addClass('open');

        }
        
      var t = $(this);
      var tp = t.next();
      var p = t.parent().siblings().find('.content');
      tp.slideToggle();
      p.slideUp();
    
    });

  // -----------------
  // Smooth scroll top
  // -----------------

   if (window.matchMedia("(min-width: 64em)").matches) {
     $(window).scroll(function() {
       if ($(this).scrollTop() > 400) {
         $(".top").fadeIn();
       } else {
         $(".top").fadeOut();
       }
     });
     $(function() {
  $('a[name]').addClass('anchor_offset');
  /*en cas de menú sticky, alçada àncores. Afegir css:
     .offset:before {
         content: '';
         display: block;
         height: 120px; en funció l’alçada del menú sticky
         margin-top: -120px; en funció l’alçada del menú sticky
         visibility: hidden;
         }*/
       $('a[href*="#"]:not([href="#"],[href="#menu"])').click(function() {
         if (
           location.pathname.replace(/^\//, "") ==
             this.pathname.replace(/^\//, "") &&
           location.hostname == this.hostname
         ) {
           var target = $(this.hash);
           target = target.length
             ? target
             : $("[name=" + this.hash.slice(1) + "]");
           //  target = target.length ? target : $(‘[name=’ + this.hash.slice(1) +‘]’);
           if (target.length) {
             //e.preventDefault();
             history.pushState({}, "", this.href);
             $("html, body").animate(
               {
                 scrollTop: target.offset().top
               },
               1000
             );
             return false;
           }
         }
       });
     });
   }

  // -------------
  // Sticky header
  // -------------

  // var handleMatchMedia2 = function(mediaQuery) {
  //     // ≥ 1024px
  //     if (mediaQuery.matches) {
  //       var lastScrollTop = 115;
  //       $(window).scroll(function(event) {
  //         var st = $(this).scrollTop();
  //         if (st > lastScrollTop) {
  //           $(".main-header-sub").addClass("sticky");
  //           $("body").addClass("sticky-header");
  //         } else {
  //           $(".main-header-sub").removeClass("sticky");
  //           $("body").removeClass("sticky-header");
  //         }
  //         // lastScrollTop = st;
  //       });
  //     }
  //   },
  //   mq2 = window.matchMedia("only screen and (min-width: 1024px)");
  // handleMatchMedia2(mq2);
  // mq2.addListener(handleMatchMedia2);

  // ------------
  // Match height
  // ------------

  //$(".box").matchHeight();
  $(".boxes-list-brown a").matchHeight();

  // --------------
  // Toggle idiomes
  // --------------

  $(".lang-toggle").click(function(event) {
    event.stopPropagation();
    $(this).toggleClass("close");
    $(this)
      .next()
      .toggleClass("is-hidden");
  });
  $(document).click(function() {
    $(".lang-toggle")
      .next()
      .addClass("is-hidden");
  });

  // -------
  // Sliders
  // -------

  $(".slider").slick({
    dots: true,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 12000,
    adaptiveHeight: true,
    cssEase: 'linear',
    nextArrow: "<img class='next slick-next' src='/media/img/boto-seguent.svg'>",
    prevArrow: "<img class='prev slick-prev' src='/media/img/boto-anterior.svg'>"
  });

  $(".slider-gallery").slick({
    // dots: true,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 12000,
    adaptiveHeight: true,
    cssEase: 'linear',
    nextArrow: "<img class='next slick-next' src='/media/img/boto-seguent.svg'>",
    prevArrow: "<img class='prev slick-prev' src='/media/img/boto-anterior.svg'>"
  });
});
