var CookieManager = {
  text: {
    ca:
      "En visitar el nostre lloc web, acceptes les cookies que utilitzem per millorar la navegació.",
    es:
      "Al visitar nuestro sitio web, aceptas las cookies que usamos para mejorar la navegación.",
    en:
      "By visiting our website, you agree to our use of cookies to enhance your experience.",
    fr:
      "En continuant d’utiliser notre site, vous acceptez des cookies que nous utilisons pour améliorer la navigation."
  },
  textButton: {
    ca: "D’acord",
    es: "Aceptar",
    en: "OK",
    fr: "Accepter"
  },
  textInfo: {
    ca: "Més informació.",
    es: "Más información.",
    en: "More information.",
    fr: "En savoir plus."
  },
  urlInfo: {
    ca: "/politica-de-cookies/",
    es: "/es/politica-de-cookies/",
    // en: '/en/politica-de-cookies/',
    en: "/cookies-policy/",
    fr: "/fr/politica-de-cookies/"
  },
  htmlCode:
    '<div id="cookies" class="cookies hide-for-print">' +
    '<div class="column">' +
    '<p>{text} <a class="noaccept" href="{urlInfo}">{textInfo}</a> <a class="btn secondary acceptar" href="javascript:void(0);">{textButton}</a></p>' +
    "</div>" +
    "</div>",

  cookieSetter: null,
  cookieGetter: null,
  cookie: function() {
    var cookie = {
      nombre: "",
      valor: ""
    };
    return cookie;
  },
  addCookie: function(s) {
    var indexOfSeparator = s.indexOf("=");
    var key = s.substr(0, indexOfSeparator);
    var value = s.substring(indexOfSeparator + 1);
    var galeta = new this.cookie();
    galeta.nombre = key;
    galeta.valor = value;
    this.deletedCookies.push(galeta);
  },
  deletedCookies: [],
  restoreAllCookies: function() {
    var tam = this.deletedCookies.length;
    for (var i = 0; i < tam; i++) {
      document.cookie =
        this.deletedCookies[i].nombre + "=" + this.deletedCookies[i].valor;
    }
  },
  deleteAllCookies: function() {
    var cookies = document.cookie.split(";");
    var parts = document.domain.split(".");
    var upperleveldomain = parts.join(".");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      if (name !== "PHPSESSID" && name !== "lang") {
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        document.cookie =
          name +
          "=;domain=" +
          document.domain +
          ";expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        document.cookie =
          name +
          "=;path=/;domain=" +
          document.domain +
          ";expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        if (upperleveldomain != document.domain) {
          document.cookie =
            name +
            "=;domain=" +
            upperleveldomain +
            ";expires=Thu, 01 Jan 1970 00:00:01 GMT;";
          document.cookie =
            name +
            "=;path=/;domain=" +
            upperleveldomain +
            ";expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        }
      }
    }
  },
  interval: 0,
  fakeCookieFunctions: function() {
    var timeInterval = 50;
    if (!document.__defineGetter__) {
      this.interval = setInterval(function() {
        CookieManager.deleteAllCookies();
      }, timeInterval);
    } else {
      CookieManager.cookieGetter = document.__lookupGetter__("cookie");
      CookieManager.cookieSetter = document.__lookupSetter__("cookie");
      if (!CookieManager.cookieGetter) {
        this.interval = setInterval(function() {
          CookieManager.deleteAllCookies();
        }, timeInterval);
      } else {
        document.__defineGetter__("cookie", function() {
          return "";
        });
        document.__defineSetter__("cookie", function(s) {
          CookieManager.addCookie(s);
        });
      }
    }
  },
  restoreCookieFunctions: function() {
    if (!document.__defineGetter__) {
      clearInterval(this.interval);
      this.setCookie("cookies", "true", 365 * 100);
      $("body").append(
        '<iframe id="marcoaux" style="display:none;" src="' +
          document.location +
          '"></iframe>'
      );
    } else {
      if (!this.cookieGetter) {
        clearInterval(this.interval);
        this.setCookie("cookies", "true", 365 * 100);
        $("body").append(
          '<iframe id="marcoaux" style="display:none;" src="' +
            document.location +
            '"></iframe>'
        );
      } else {
        document.__defineGetter__("cookie", this.cookieGetter);
        document.__defineSetter__("cookie", this.cookieSetter);
      }
    }
  },
  getCookie: function(cName) {
    var cValue = document.cookie;
    var cStart = cValue.indexOf(" " + cName + "=");
    if (cStart == -1) {
      cStart = cValue.indexOf(cName + "=");
    }
    if (cStart == -1) {
      cValue = null;
    } else {
      cStart = cValue.indexOf("=", cStart) + 1;
      var cEnd = cValue.indexOf(";", cStart);
      if (cEnd == -1) {
        cEnd = cValue.length;
      }
      cValue = unescape(cValue.substring(cStart, cEnd));
    }
    return cValue;
  },
  setCookie: function(cName, value, exdays) {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    var cValue =
      escape(value) +
      (exdays === null ? "" : "; expires=" + exdate.toUTCString());
    document.cookie = cName + "=" + cValue + "; path=/";
  },
  noCookies: function() {
    var cookie = this.getCookie("cookies");
    return cookie === undefined || cookie === null || cookie === "";
  },
  noCookiesNav: function() {
    var cookieNav = this.getCookie("cookiesNav");
    return cookieNav === undefined || cookieNav === null || cookieNav === "";
  },
  firstTime: true,
  activateCookies: function(showMessage) {
    if (this.firstTime) {
      this.restoreCookieFunctions();
      this.restoreAllCookies();
      this.setCookie("cookies", "true", 365 * 100);
      this.firstTime = false;
    }
    if (showMessage === false) {
      $("#cookies").remove();
      this.setCookie("cookiesNav", "true", 365 * 100);
    }
  },
  deactivateCookies: function(selector, deleteCookies) {
    var idioma = $("html").attr("lang");

    var text =
      typeof this.text[idioma] == "undefined"
        ? this.text.first()
        : this.text[idioma];
    var textButton =
      typeof this.textButton[idioma] == "undefined"
        ? this.textButton.first()
        : this.textButton[idioma];
    var textInfo =
      typeof this.textInfo[idioma] == "undefined"
        ? this.textInfo.first()
        : this.textInfo[idioma];
    var urlInfo =
      typeof this.urlInfo[idioma] == "undefined"
        ? this.urlInfo.first()
        : this.urlInfo[idioma];

    $(selector).prepend(
      this.htmlCode
        .replace("{text}", text)
        .replace("{textButton}", textButton)
        .replace("{textInfo}", textInfo)
        .replace("{urlInfo}", urlInfo)
    );

    if (deleteCookies) {
      this.deleteAllCookies();
      this.fakeCookieFunctions();
    }
  },
  init: function(selector) {
    var noCookies = this.noCookies();
    var noCookiesNav = this.noCookiesNav();
    if (noCookies || noCookiesNav) {
      this.deactivateCookies(selector, noCookies);
      $("a").click(function() {
        if ($(this).hasClass("acceptar")) {
          CookieManager.activateCookies(false);
        } else if (!$(this).hasClass("noaccept")) {
          CookieManager.activateCookies(true);
        }
      });
      $(window).scroll(function() {
        CookieManager.activateCookies(true);
      });
    }
  },

  initAllDeactivate: function() {
    var noCookies = this.noCookies();
    var noCookiesNav = this.noCookiesNav();
    if (noCookies || noCookiesNav) {
      this.deleteAllCookies();
      this.fakeCookieFunctions();
    }
  }
};
$(function() {
  if (navigator.cookieEnabled) {
    /*        if ($('body#nocookies').length == 0) {
            CookieManager.init('body');
        }*/
    if ($("body#nocookies").length == 1) {
      CookieManager.initAllDeactivate();
    } else {
      CookieManager.init("body");
    }
  }
});
